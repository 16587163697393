import packageServices from '@/services/package-services';
import { ref, watch } from '@vue/composition-api';
export default function useUsersList()
{
    const userListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            align: 'start',
            value: 'id',
            sortable: false,
            align: 'center'
        },
        { text: 'Name', value: 'name', sortable: false, align: 'center' },
        {
            text: 'ACTIONS',
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const selectedTableData = ref([])

    const fetchUsers = () =>
    {
        
        const userData = JSON.parse(localStorage.getItem('userData'));
        if(searchQuery.value){
            userData.id = searchQuery.value;
        }
        if (userData.role == 'boat_owner' || searchQuery.value)
        {
            packageServices.getCityList(options,id).then(resp =>
            {
                // const { filteredData, total } = response.data
                if (resp.statusCode == 200)
                {
                    userListTable.value = resp.data.cities
                    totalUserListTable.value = resp.data.pagination.count
                }
                loading.value = false
                // console.log(resp)
            })
        }
        else
        {
            packageServices.getCityList(options).then(resp =>
            {
                // const { filteredData, total } = response.data
                if (resp.statusCode == 200)
                {
                    userListTable.value = resp.data.cities
                    totalUserListTable.value = resp.data.pagination.count
                }
                loading.value = false
                // console.log(resp)
            })
        }

    }

    watch([searchQuery, statusFilter, options], () =>
    {
        // start loading
        loading.value = true
        selectedTableData.value = []
        fetchUsers()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        options,
        userListTable,
        statusFilter,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchUsers,
        resolveRole,
    }
}
