import axios from './axiosInit'
const saveCity = async (body) =>
{
    try
    {

        const result = await axios.post('cities/create', body)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateCity = async (body) =>
{
    try
    {
        const result = await axios.post('cities/update', body)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const deleteCity = async (body) =>
{
    try
    {
        const result = await axios.post('cities/remove', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const getCityById = async (id) =>
{
    try
    {
        const result = await axios.get('cities/list?id=' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateStatus = async (url, body) =>
{
    try
    {
        const result = await axios.post(url, body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


export default {
    saveCity,
    deleteCity,
    getCityById,
    updateCity,
    updateStatus
}