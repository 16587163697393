import axios from './axiosInit'

const saveMarina = async body => {
  try {
    const result = await axios.post('marinas/create', body)

    return result
  } catch (e) {
    console.log(e)

    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const updateMarina = async body => {
  try {
    const result = await axios.post('marinas/update', body)

    return result
  } catch (e) {
    console.log(e)

    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const deleteMarina = async body => {
  try {
    const result = await axios.post('marinas/remove', body)

    return result.data
  } catch (e) {
    console.log(e)

    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const getMarinaById = async id => {
  try {
    const result = await axios.get(`marinas/list?id=${id}`)

    return result.data
  } catch (e) {
    console.log(e)

    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const updateStatus = async (url, body) =>
{
    try
    {
        const result = await axios.post(url, body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
  saveMarina,
  deleteMarina,
  getMarinaById,
  updateMarina,
  updateStatus
}
