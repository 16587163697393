var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-black font-weight-bold size"},[_vm._v(_vm._s(_vm.$t('System Parameters')))]),_c('v-divider',{staticClass:"mt-5 mb-7",attrs:{"color":"white"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.text},[_c('v-card',{staticClass:"py-2 rounded-lg mb-4 card-btn",style:({
                            color: index === _vm.active ? 'white' : '#00B2A9',
                            backgroundColor: index === _vm.active ? '#00B2A9' : 'white',
                            border: index === _vm.active ? '' : '1px solid #fff'
                        }),on:{"click":function($event){return _vm.showComponent(item, index)}}},[_c('v-card-title',[_c('span',{staticClass:"weight card-btn parameters-color",style:({
                            color: index === _vm.active ? 'white' : '#00B2A9',
                            backgroundColor: index === _vm.active ? '#00B2A9' : 'white',
                        })},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)],1)}),0),(_vm.showContactDetails)?_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[_c('ContactDetails',{attrs:{"contact-details":_vm.contactDetails},on:{"save":_vm.submitData}})],1):_vm._e(),(_vm.showCityDetails)?_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[(_vm.showCity)?_c('cityDetail',{on:{"updateCity":_vm.updateCity,"viewCity":_vm.viewCity,"showForm":_vm.showForm}}):_vm._e(),(!_vm.showCity)?_c('city',{attrs:{"cities":_vm.cities,"disable":_vm.cityDisable},on:{"saveCity":_vm.saveCity,"showList":_vm.showList}}):_vm._e()],1):_vm._e(),(_vm.showMarinaDetails)?_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[(_vm.showMarina)?_c('marinaList',{on:{"updateMarina":_vm.updateMarina,"viewMarina":_vm.viewMarina,"showMarinaForm":_vm.showMarinaForm}}):_vm._e(),(!_vm.showMarina)?_c('marina',{attrs:{"marinas":_vm.marinas,"disable":_vm.marinaDisable},on:{"saveMarina":_vm.saveMarina,"showMarinaList":_vm.showMarinaList}}):_vm._e()],1):_vm._e(),(_vm.showBoatCategories)?_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[(!_vm.showCategory)?_c('BoatCategories',{attrs:{"category":_vm.category,"disable":_vm.disable},on:{"saveBoat":_vm.submitBoat,"updateBoat":_vm.updateBoat,"back":_vm.back}}):_vm._e(),(_vm.showCategory)?_c('categoryList',{on:{"update":_vm.updateData,"view":_vm.viewData,"add":_vm.add}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }