<template>
    <v-form class="multi-col-validation"
            lazy-validation
            ref="form"
            v-model="valid">
        <v-card class="py-3">
            <v-card-title class="weight">
                <div class="color text-green">{{ $t('Contact Details') }}</div>
            </v-card-title>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Calling Number') }}
                        </p>
                        <v-text-field dense
                                      v-model="contact_number"
                                      :rules="numberRule"
                                      v-mask="'###############'"
                                      :placeholder="$t('Enter Calling Number')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Email Address') }}
                        </p>
                        <v-text-field dense
                                      v-model="email"
                                      :rules="emailRule"
                                      :placeholder="$t('Enter Email Address')">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('WhatsApp Number') }}
                        </p>
                        <v-text-field dense
                                      v-model="whatsapp_number"
                                      v-mask="'###############'"
                                      :rules="numberRule"
                                      :placeholder="$t('Enter WhatsApp Number')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Twitter URL') }}
                        </p>
                        <v-text-field dense
                                      v-model="twitter_url"
                                      :placeholder="$t('Enter Twitter URL')"></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <p class="font-weight-semibold mb-0 text-black">
                            {{ $t('Instagram URL') }}
                        </p>
                        <v-text-field dense
                                      v-model="instagram_url"
                                      :placeholder="$t('Enter Instagram URL')"></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-card-actions>
                <!-- <v-btn class="text-capitalize px-9"
                       outlined
                       color="#00B2A9">Back</v-btn> -->
                <v-btn @click="submit"
                       :disabled="!valid"
                       class="text-capitalize px-11 white--text owner-info-btn border-card"
                       color="#00B2A9">{{ $t('Save') }}</v-btn>

            </v-card-actions>
        </v-card>

    </v-form>
</template>

<script>
export default {
    props: {
        contactDetails: {
            default()
            {
                return {}
            },
            type: Object
        },
    },
    data: () => ({
        valid: false,
        emailRule: [
            v => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v) || 'Enter a valid Email.'
        ],
        numberRule: [
            // value => !!value || 'Phone Number is required.',
            value => (value && value.length == 10) || 'Number must be 10 digits.',
        ],
        contact_number: '',
        email: '',
        whatsapp_number: '',
        twitter_url: '',
        instagram_url: '',
    }),

    methods: {
        submit()
        {
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {

                let obj = {
                    id: this.localObject.id,
                    contact_number: this.contact_number,
                    email: this.email,
                    whatsapp_number: this.whatsapp_number,
                    twitter_url: this.twitter_url,
                    instagram_url: this.instagram_url
                }
                this.$emit('save', obj);
            }
        }
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        contactDetails: {
            immediate: true,
            handler(newVal)
            {

                this.localObject = Object.assign({}, newVal);
                this.contact_number = this.localObject.contact_number;
                this.email = this.localObject.email;
                this.whatsapp_number = this.localObject.whatsapp_number;
                this.twitter_url = this.localObject.twitter_url;
                this.instagram_url = this.localObject.instagram_url;


            }
        }
    }

}
</script>

<style scoped>
.weight {
    font-weight: 600;
}

.color {
    color: #00B2A9;
}
</style>



