<template>
    <div>
        <v-card id="user-list"
                class="mt-0">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <div class="font-weight-semibold text-base text-green text-color">
                        {{ $t('Marina Listing') }}
                    </div>
                    <!-- <v-spacer></v-spacer>
                    <div class="d-flex align-center">

                    </div> -->
                </div>

                <div class="d-flex align-center pb-5">
                    <v-select :items="cities"
                              single-line
                              dense
                              outlined
                              hide-details
                              v-model="searchQuery"
                              :label="$t('City')"
                              @change="onChangeFilter()"
                              item-value="id"
                              item-text="name"
                              class="user-list-search me-3"></v-select>

                    <v-btn color="primary"
                           class="me-3 text-lower owner-info-btn border-card"
                           @click="addForm">
                        <span>{{ $t('Add Marina') }}</span>
                    </v-btn>
                </div>
            </v-card-text>
            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :options.sync="options"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          class="text-no-wrap my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">
                    <div class="text-black font-size">
                        {{ item.id }}
                    </div>
                </template>
                <template #item.name="{ item }">
                    <div class="text-black font-size">
                        {{ item.name }}
                    </div>
                </template>
                <template #item.city.name="{ item }">
                    <div class="text-black font-size">
                        {{ item.city.name }}
                    </div>
                </template>


                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <template bottom>
                            <v-switch v-model="item.status"
                                      inset
                                      small
                                      @click="updateStatus(item)"
                                      value="1">
                            </v-switch>
                        </template>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       color="#00B2A9"
                                       @click="updateData(item.id)"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Edit') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       color="#00B2A9"
                                       @click="deleteData(item.id)"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        {{ icons.mdiDelete }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Delete') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="viewData(item.id)"
                                       v-on="on">
                                    <v-icon size="20"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mdiDelete, mdiEyeOutline } from '@mdi/js'
import darkMode from '@core/utils/modalColor'

// composition function
import marinaServices from '@/services/marina-services';
import sharedServices from '@/services/shared-services';

import useMarinaList from './useMarinaList'

export default {
    data: () => ({
        applicationList: '',
        boatOwners: [],
        admin: false,
        cities: []
    }),
    mounted()
    {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData.role === 'admin')
        {
            this.admin = true
        }
        this.getCities();
    },
    methods: {
        updateStatus(item)
        {

            let url;
            let body;
            if (item.status == '1')
            {
                url = 'marinas/update'
                body = {
                    "id": item.id,
                    "status": "0"
                }
            }
            else
            {
                url = 'marinas/update'
                body = {
                    "id": item.id,
                    "status": "1"
                }
            }
            marinaServices.updateStatus(url, body).then(resp =>
            {
                // console.log(resp);
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    let status = resp.data.marina.status == '1' ? 'Marina has been activated successfully' : 'Marina has been deactivated successfully';
                    this.$swal({
                        text: `${status}`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        // refresh here
                    })
                }
            }).catch(error =>
            {
                // console.log(error);
                this.$swal({
                    title: `Error`,
                    text: `Something went wrong!`,
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    icon: 'error',
                });
            })
        },
        getCities()
        {
            this.cities = [];


            sharedServices.getCities().then(resp =>
            {
                this.cities = resp.data.cities;
                this.cities.unshift({ id: null, name: 'All' });
            })
        },
        addForm()
        {
            // console.log('ADD FORM')
            this.$emit('showMarinaForm', false)
        },
        add()
        {
            this.$emit('add', true)
        },
        updateData(item)
        {
            this.$emit('updateMarina', item)
        },
        viewData(item)
        {
            this.$emit('viewMarina', item)
        },
        deleteData(item)
        {
            this.$swal({
                title: 'Delete',
                text: 'Do you want to delete marina?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: darkMode.getTextColor(),
                customClass: darkMode.getBackgroundColor(),
            }).then((result) =>
            {
                if (result.isConfirmed)
                {
                    const body = {
                        id: item,
                    }
                    marinaServices.deleteMarina(body).then(resp =>
                    {
                        if (resp.statusCode == 200)
                        {

                            if (resp.status == 'success')
                            {
                                this.options = [];
                                this.$swal({
                                    text: `Deleted successfully`,
                                    confirmButtonColor: darkMode.getTextColor(),
                                    customClass: darkMode.getBackgroundColor(),
                                    icon: 'success',
                                }).then((result) =>
                                {

                                });
                            }
                            else
                            {
                                // console.log(resp);
                                this.$swal({
                                    title: `Delete`,
                                    text: resp.message,
                                    confirmButtonColor: darkMode.getTextColor(),
                                    customClass: darkMode.getBackgroundColor(),
                                    icon: 'error',
                                });
                            }
                        }
                        else
                        {
                            // console.log(resp);
                            this.$swal({
                                title: `Error`,
                                text: `Some Error`,
                                confirmButtonColor: darkMode.getTextColor(),
                                customClass: darkMode.getBackgroundColor(),
                                icon: 'error',
                            });
                        }
                    }).catch(error =>
                    {
                        // console.log(error);
                        this.$swal({
                            title: `Error`,
                            text: `Some Error`,
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'error',
                        });
                    })

                }
            })


        },
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
        } = useMarinaList()

        return {
            tableColumns,
            searchQuery,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,

            icons: {
                mdiEyeOutline, mdiDelete,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.text-color {
    color: #00B2A9;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 3.125rem;
    border-left: 1px solid #efeded !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(94, 86, 105, 0.14);
    border-right: 1px solid #efeded;
}
</style>
