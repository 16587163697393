var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-0",attrs:{"id":"user-list"}},[_c('v-card-text',{staticClass:"d-flex justify-space-between align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('div',{staticClass:"font-weight-semibold text-base text-green text-color"},[_vm._v(" "+_vm._s(_vm.$t('Marina Listing'))+" ")])]),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-select',{staticClass:"user-list-search me-3",attrs:{"items":_vm.cities,"single-line":"","dense":"","outlined":"","hide-details":"","label":_vm.$t('City'),"item-value":"id","item-text":"name"},on:{"change":function($event){return _vm.onChangeFilter()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"me-3 text-lower owner-info-btn border-card",attrs:{"color":"primary"},on:{"click":_vm.addForm}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add Marina')))])])],1)]),_c('v-data-table',{staticClass:"text-no-wrap my-data-table",attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"options":_vm.options,"server-items-length":_vm.totalUserListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.trending",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-black font-size"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-black font-size"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.city.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-black font-size"},[_vm._v(" "+_vm._s(item.city.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[[_c('v-switch',{attrs:{"inset":"","small":"","value":"1"},on:{"click":function($event){return _vm.updateStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#00B2A9"},on:{"click":function($event){return _vm.updateData(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-green",attrs:{"size":"18"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#00B2A9"},on:{"click":function($event){return _vm.deleteData(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-green",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#00B2A9"},on:{"click":function($event){return _vm.viewData(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-green",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('View')))])])],2)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }