import packageServices from '@/services/package-services'
// eslint-disable-next-line import/order
import { ref, watch } from '@vue/composition-api'

export default function useMarinasList()
{
    const userListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            value: 'id',
            sortable: false,
            align: 'center',
        },
        {
            text: 'Marina', value: 'name', sortable: false, align: 'center',
        },
        {
            text: 'City', value: 'city.name', sortable: false, align: 'center',
        },
        {
            text: 'ACTIONS',
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const selectedTableData = ref([])

    const fetchMarinas = () =>
    {
        const userData = JSON.parse(localStorage.getItem('userData'))

        // if(searchQuery?.value){
        //     data.role = 'sub_admin&status='+ searchQuery?.value
        // }

        if (searchQuery.value)
        {
            userData.id = searchQuery.value
        }
        if (userData.role === 'boat_owner' || searchQuery.value)
        {
            packageServices.getMarinaList(options, userData).then(resp =>
            {
                // const { filteredData, total } = response.data
                if (resp.statusCode === 200)
                {
                    userListTable.value = resp.data.marinas
                    totalUserListTable.value = resp.data.pagination.count
                }
                loading.value = false
                // console.log(resp)
            })
        } else
        {
            packageServices.getMarinaList(options).then(resp =>
            {
                // const { filteredData, total } = response.data
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    userListTable.value = resp.data.marinas
                    totalUserListTable.value = resp.data.pagination.count
                }
                loading.value = false
                // console.log(resp)
            })
        }
    }

    const onChangeFilter = () =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchMarinas();
    };

    watch([options], () =>
    {
        // start loading
        loading.value = true
        selectedTableData.value = []
        fetchMarinas()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    fetchMarinas();


    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        options,
        userListTable,
        statusFilter,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchMarinas,
        resolveRole,
        onChangeFilter
    }
}
