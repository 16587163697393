<template>
    <div>
        <p class="text-black font-weight-bold size">{{ $t('System Parameters') }}</p>
        <v-divider color="white"
                   class="mt-5 mb-7"></v-divider>
        <v-row>
            <v-col cols="12"
                   md="4"
                   sm="4">
                <div v-for="(item, index) in items"
                     :key="item.text">
                    <v-card class="py-2 rounded-lg mb-4 card-btn"
                            @click="showComponent(item, index)"
                            :style="{
                                color: index === active ? 'white' : '#00B2A9',
                                backgroundColor: index === active ? '#00B2A9' : 'white',
                                border: index === active ? '' : '1px solid #fff'
                            }">
                        <v-card-title>
                            <span :style="{
                                color: index === active ? 'white' : '#00B2A9',
                                backgroundColor: index === active ? '#00B2A9' : 'white',
                            }"
                                  class="weight card-btn parameters-color">
                                {{ $t(item.text) }}
                            </span>
                        </v-card-title>
                    </v-card>
                </div>
            </v-col>

            <v-col v-if="showContactDetails"
                   cols="12"
                   md="8"
                   sm="8">
                <ContactDetails :contact-details="contactDetails"
                                @save="submitData"></ContactDetails>
            </v-col>

            <v-col v-if="showCityDetails"
                   cols="12"
                   md="8"
                   sm="8">
                <cityDetail v-if="showCity"
                            @updateCity="updateCity"
                            @viewCity="viewCity"
                            @showForm="showForm"></cityDetail>
                <city v-if="!showCity"
                      :cities="cities"
                      :disable="cityDisable"
                      @saveCity="saveCity"
                      @showList="showList"></city>
            </v-col>
            <v-col v-if="showMarinaDetails"
                   cols="12"
                   md="8"
                   sm="8">
                <marinaList v-if="showMarina"
                            @updateMarina="updateMarina"
                            @viewMarina="viewMarina"
                            @showMarinaForm="showMarinaForm"></marinaList>
                <marina v-if="!showMarina"
                        :marinas="marinas"
                        :disable="marinaDisable"
                        @saveMarina="saveMarina"
                        @showMarinaList="showMarinaList"></marina>
            </v-col>

            <v-col v-if="showBoatCategories"
                   cols="12"
                   md="8"
                   sm="8">
                <BoatCategories v-if="!showCategory"
                                :category="category"
                                :disable="disable"
                                @saveBoat="submitBoat"
                                @updateBoat="updateBoat"
                                @back="back"></BoatCategories>

                <categoryList v-if="showCategory"
                              @update="updateData"
                              @view="viewData"
                              @add="add"></categoryList>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import darkMode from '@core/utils/modalColor'
import packageServices from '@/services/package-services'

import cityDetail from './city/CityList.vue'
import marinaList from './marina/MarinaList.vue'
import BoatCategories from './BoatCategory.vue'
import ContactDetails from './ContactDetail.vue'

import city from './City.vue'
import marina from './Marina.vue'

// import marina from ''

import cityServices from '@/services/city-services'
import marinaServices from '@/services/marina-services'
import categoryList from './category/CategoryList.vue'

export default {
    components: {
        ContactDetails,
        BoatCategories,
        categoryList,
        cityDetail,
        city,
        marina,
        marinaList,
    },
    data()
    {
        return {
            active: 0,
            showBoatCategories: true,
            category: {},
            showCategory: true,
            showContactDetails: false,
            showCityDetails: false,
            showMarinaDetails: false,
            disable: false,
            cityDisable: false,
            marinaDisable: false,
            showCity: true,
            showMarina: true,
            contactDetails: {},
            items: [
                { text: 'Boat Categories', showBoatCategories: 'true' },
                { text: 'Cities', showCityDetails: 'true' },
                { text: 'Marinas', showMarinaDetails: 'true' },
                { text: 'Contact Details', showContactDetails: 'true' },
            ],
        }
    },
    mounted()
    {
        // packageServices.getContactDetail().then(resp =>
        // {
        //     // console.log(resp);
        //     if (resp.statusCode == 200)
        //     {
        //         this.contactDetails = resp.data.contactDetail;
        //     }
        // })
    },
    methods: {
        showMarinaForm()
        {
            // console.log('System Prefrence : Show Marina FORM')
            this.showMarina = false
            this.marinaDisable = false
            this.marinas = {}
        },
        showMarinaList()
        {
            this.showMarina = true
        },
        showForm()
        {
            this.showCity = false

            // this.showMarina = false
            this.cityDisable = false
            this.cities = {}
        },
        showList()
        {
            this.showCity = true

            // this.showMarina = true
        },
        saveMarina(event)
        {
            // console.log(event)
            if (event?.id)
            {
                marinaServices.updateMarina(event).then(res =>
                {
                    if (res?.data?.statusCode === 200)
                    {
                        this.$swal({
                            title: '',
                            text: 'Marina has been updated successfully.',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then(result =>
                        {
                            if (result.isConfirmed)
                            {
                                this.showMarina = true
                            }
                        })
                    } else
                    {
                        this.$swal({
                            icon: 'error',
                            // title: 'Marina',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                })
            } else
            {
                marinaServices.saveMarina(event).then(res =>
                {
                    if (res?.data?.statusCode === 200)
                    {
                        this.$swal({
                            // title: 'Marina',
                            text: 'Marina has been added successfully.',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then(result =>
                        {
                            if (result.isConfirmed)
                            {
                                this.showMarina = true
                            }
                        })
                    } else
                    {
                        this.$swal({
                            icon: 'error',
                            // title: 'Marina',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                })
            }
        },
        saveCity(event)
        {
            // console.log(event);
            if (event?.id)
            {
                cityServices.updateCity(event).then(res =>
                {
                    if (res?.data?.statusCode == 200)
                    {

                        this.$swal({
                            title: '',
                            text: 'City has been updated successfully.',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then(result =>
                        {
                            if (result.isConfirmed)
                            {
                                this.showCity = true
                            }
                        })
                    } else
                    {
                        this.$swal({
                            icon: 'error',
                            // title: 'City',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                })
            }
            else
            {
                cityServices.saveCity(event).then(res =>
                {
                    if (res?.data?.statusCode == 200)
                    {

                        this.$swal({
                            // title: 'City',
                            text: 'City has been added successfully.',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then(result =>
                        {
                            if (result.isConfirmed)
                            {
                                this.showCity = true;
                            }
                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            // title: 'City',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                })
            }

        },
        add()
        {
            this.showCategory = false;
            this.disable = false;
            this.category = {};
        },
        back()
        {
            this.showCategory = true;
        },
        updateCity(event)
        {
            // console.log(event);
            cityServices.getCityById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showCity = false;
                    this.cities = resp.data.cities[0];
                    this.cityDisable = false;

                }
            })
        },
        viewCity(event)
        {
            // console.log(event);
            cityServices.getCityById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showCity = false
                    this.cities = resp.data.cities[0]
                    this.cityDisable = true
                }
            })
        },
        viewMarina(event)
        {
            // console.log(event)
            // console.log(event)
            marinaServices.getMarinaById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showMarina = false
                    this.marinas = resp.data.marinas[0]
                    this.marinaDisable = true
                }
            })
        },
        updateMarina(event)
        {
            // console.log(event)
            marinaServices.getMarinaById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showMarina = false
                    this.marinas = resp.data.marinas[0]
                    this.marinaDisable = false
                }
            })
        },
        updateData(event)
        {
            // console.log(event)
            packageServices.getCategoryById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showCategory = false
                    this.category = resp.data.categories[0]
                    this.disable = false
                }
            })
        },
        viewData(event)
        {
            // console.log(event)
            packageServices.getCategoryById(event).then(resp =>
            {
                // console.log(resp)
                if (resp.statusCode === 200)
                {
                    this.showCategory = false
                    this.category = resp.data.categories[0]
                    this.disable = true
                }
            })
        },
        submitBoat(event)
        {
            packageServices.saveBoatCategory(event).then(res =>
            {

                if (res.statusCode == 200)
                {
                    this.$swal({
                        text: 'Category has been added successfully.',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        this.showCategory = true;
                    });
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        // title: 'Category',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
            })
        },

        updateBoat(event)
        {
            packageServices.categoryUpdate(event).then(res =>
            {

                if (res.statusCode == 200)
                {
                    this.$swal({
                        text: 'Category has been updated successfully.',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        this.showCategory = true;
                    });
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        // title: 'Category',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
            })
        },
        submitData(event)
        {
            const body = {
                id: event.id,
                contact_number: event.contact_number,
                email: event.email,
                whatsapp_number: event.whatsapp_number,
                twitter_url: event.twitter_url,
                instagram_url: event.instagram_url,
            }
            packageServices.saveContactDetail(body).then(res =>
            {
                // // console.log(resp);
                // if (resp.statusCode == 200)
                // {
                //     this.contactDetails = resp.data.contactDetail;
                // }
                // console.log(res);
                if (res.statusCode == 200)
                {
                    this.contactDetails = res.data.contact_detail;
                    this.$swal({
                        text: 'Contact has been added successfully.',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {

                    });
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        // title: 'Contact',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
            })
        },
        showComponent(item, index)
        {
            this.active = index;
            if (item.showContactDetails)
            {
                packageServices.getContactDetail().then(resp =>
                {
                    // console.log(resp)
                    if (resp.statusCode === 200)
                    {
                        this.contactDetails = resp.data.contactDetail
                    }
                })
            }
            this.showBoatCategories = item.showBoatCategories
            if (this.showBoatCategories)
            {
                this.showCategory = true
            }
            this.showContactDetails = item.showContactDetails

            this.showCityDetails = item.showCityDetails
            if (this.showCityDetails)
            {
                this.showCity = true
            }
            this.showMarinaDetails = item.showMarinaDetails
            if (this.showMarinaDetails)
            {
                this.showMarina = true
            }
            // console.log(this.showCityDetails)
            // console.log(this.showBoatCategories)
        },
    },
}
</script>

<style scoped>
.card-btn {
    background-color: #fff;
    color: #00B2A9;
}

.selected {
    color: white;
    background-color: #00B2A9;
}


.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none !important
}

.text-color {
    color: #00B2A9;
    /* background-color: #fff; */
}

.size {
    font-size: 20px;
}

.weight {
    font-weight: 600;
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

.past {
    background-color: #e9e9e9 !important;
    color: #00B2A9;
}
</style>



