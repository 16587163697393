<template>
    <div>
        <v-card class="py-3">
            <v-card-title class="weight">
                <div class="color text-green">
                    {{ $t('Marina Details') }}
                </div>
            </v-card-title>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field v-model="name"
                                      dense
                                      :disabled="disable"
                                      :placeholder="$t('Name En')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field class="write-ar"
                                      v-model="name_ar"
                                      dense
                                      :disabled="disable"
                                      :placeholder="$t('Name Ar')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-select v-model="city_id"
                                  :items="cities"
                                  :placeholder="$t('City')"
                                  dense
                                  :disabled="disable"
                                  item-text="name"
                                  item-value="id"
                                  :rules="[genericRules.required]"></v-select>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field dense
                                      v-model="latitude"
                                      v-mask="'##.######'"
                                      :disabled="disable"
                                      :placeholder="$t('Latitude')"></v-text-field>
                    </v-card-text>
                </v-col>

                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field dense
                                      v-model="longitude"
                                      v-mask="'##.######'"
                                      :disabled="disable"
                                      :placeholder="$t('Longitude')"></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-card-actions v-if="!disable">
                <v-btn class="text-capitalize px-9 text-black"
                       outlined
                       color="#00B2A9"
                       @click="back">
                    {{ $t('Back') }}
                </v-btn>
                <v-btn :disabled="!city_id"
                       class="text-capitalize px-11 white--text owner-info-btn border-card"
                       color="#00B2A9"
                       @click="submit">
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
  
<script>
import sharedServices from '@/services/shared-services'

export default {
    props: {
        marinas: {
            default()
            {
                return {}
            },
            type: Object,
        },
        disable: {
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        name: '',
        genericRules: {
            required: v => !!v || 'Field is required',
        },
        city_id: '',
        latitude: '',
        longitude: '',
        name_ar: '',
        cities: [],
    }),
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        marinas: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = { ...newVal }
                this.name = this.localObject.name
                this.name_ar = this.localObject.name_ar;
                this.id = this.localObject.id
                this.latitude = this.localObject.latitude
                this.longitude = this.localObject.longitude
                this.city_id = +this.localObject.city_id
            },
        },
    },

    mounted()
    {
        sharedServices.getCities().then(resp =>
        {
            this.cities = resp.data.cities
        })
    },

    methods: {
        submit()
        {
            if (this.id)
            {
                const obj = {
                    name: this.name,
                    name_ar: this.name_ar,
                    id: this.id,
                    city_id: this.city_id,
                    latitude: this.latitude,
                    longitude: this.longitude
                }
                this.$emit('saveMarina', obj);
            } else
            {
                const obj = {
                    name: this.name,
                    name_ar: this.name_ar,
                    city_id: this.city_id,
                    latitude: this.latitude,
                    longitude: this.longitude
                }
                this.$emit('saveMarina', obj);
            }
        },
        back()
        {
            this.$emit('showMarinaList')
        },
    },

}
</script>
  
<style scoped>
.weight {
    font-weight: 600;
}

.color {
    color: #00B2A9;
}

.text-fields {
    margin: -4px 0px 0px;
}
</style>
  