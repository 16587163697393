<template>
    <div>
        <v-card class="py-3">
            <v-card-title class="weight">
                <div class="color text-green">{{ $t('City Details') }}</div>
            </v-card-title>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field dense
                                      v-model="name"
                                      :disabled="disable"
                                      :placeholder="$t('Name En')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field class="write-ar"
                                      dense
                                      v-model="name_ar"
                                      :disabled="disable"
                                      :placeholder="$t('Name Ar')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field dense
                                      v-model="latitude"
                                      v-mask="'##.######'"
                                      :disabled="disable"
                                      :placeholder="$t('Latitude')"></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-text-field dense
                                      v-model="longitude"
                                      v-mask="'##.######'"
                                      :disabled="disable"
                                      :placeholder="$t('Longitude')"></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12"
                       md="5"
                       sm="6">
                    <v-card-text>
                        <v-select :items="countries"
                                  :placeholder="$t('Country')"
                                  dense
                                  :disabled="disable"
                                  item-text="name"
                                  item-value="id"
                                  :rules="[genericRules.required]"
                                  v-model="country_id"></v-select>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-card-actions v-if="!disable">
                <v-btn class="text-capitalize px-9 text-black"
                       outlined
                       color="#00B2A9"
                       @click="back">{{ $t('Back') }}</v-btn>
                <v-btn @click="submit"
                       :disabled="!country_id"
                       class="text-capitalize px-11 white--text owner-info-btn border-card"
                       color="#00B2A9">{{ $t('Save') }}</v-btn>

            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
import sharedServices from '@/services/shared-services'

export default {
    props: {
        cities: {
            default()
            {
                return {}
            },
            type: Object
        },
        disable: {
            default: false,
            type: Boolean
        },
    },
    data: () => ({
        name: '',
        name_ar: '',
        genericRules: {
            required: v => !!v || 'Field is required'
        },
        latitude: '',
        longitude: '',
        country_id: '',
        countries: []
    }),

    mounted()
    {
        sharedServices.getCountries().then(resp =>
        {
            // console.log(resp)
            this.countries = resp.data.countries;
        })
    },

    methods: {
        submit()
        {
            if (this.id)
            {
                let obj = {
                    "name": this.name,
                    "name_ar": this.name_ar,
                    "id": this.id,
                    "latitude": this.latitude,
                    "longitude": this.longitude,
                    "country_id": this.country_id
                }
                this.$emit('saveCity', obj);
            }
            else
            {
                let obj = {
                    "name": this.name,
                    "name_ar": this.name_ar,
                    "latitude": this.latitude,
                    "longitude": this.longitude,
                    "country_id": this.country_id
                }
                this.$emit('saveCity', obj);
            }

        },
        back()
        {
            this.$emit('showList');
        }
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        cities: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = Object.assign({}, newVal);
                this.name = this.localObject.name
                this.name_ar = this.localObject.name_ar
                this.id = this.localObject.id
                this.latitude = this.localObject.latitude
                this.longitude = this.localObject.longitude
                this.country_id = +this.localObject.country_id

            }
        }
    }

}
</script>

<style scoped>
.weight {
    font-weight: 600;
}

.color {
    color: #00B2A9;
}

.text-fields {
    margin: -4px 0px 0px;
}
</style>



