<template>
    <div>
        <v-card class="py-3">
            <v-card-title class="weight">
                <div class="color text-green">
                    {{ $t('Category Details') }}
                </div>
            </v-card-title>
            <v-form ref="form"
                    class="multi-col-validation">
                <v-row dense>
                    <v-col cols="12"
                           md="5"
                           sm="6">
                        <v-card-text>
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Category Name (English)') }}
                            </p>
                            <v-text-field hide-details="auto"
                                          dense
                                          :disabled="disable"
                                          v-model="name"
                                          :placeholder="$t('Enter Category Name')"></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12"
                           md="5"
                           sm="6">
                        <v-card-text>
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Category Name (Arabic)') }}
                            </p>
                            <v-text-field hide-details="auto"
                                          dense
                                          :disabled="disable"
                                          v-model="arName"
                                          :placeholder="$t('Category Name (Arabic)')"></v-text-field>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12"
                           md="10">
                        <v-card-text>
                            <p class="font-weight-medium text-body-2 text-black">
                                {{ $t('Category Description') }}
                            </p>
                            <v-textarea rows="3"
                                        hide-details="auto"
                                        class="rounded-lg"
                                        outlined
                                        :disabled="disable"
                                        v-model="description"
                                        auto-grow
                                        autocomplete="Category Description"
                                        :placeholder="$t('Enter Category Description')">
                            </v-textarea>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12"
                           md="5"
                           sm="6">
                        <v-card-text>
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Category Booking Type') }}
                            </p>
                            <v-select :items="bookingTypes"
                                      :placeholder="$t('Enter Category Booking Type')"
                                      variant="underlined"
                                      item-text="name"
                                      :disabled="disable"
                                      item-value="id"
                                      class="text-fields"
                                      @change="onChangeBookingType()"
                                      v-model="category_booking_type"></v-select>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12"
                           md="5"
                           sm="6">
                        <v-card-text>
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Minimum Time Slot') }}
                            </p>
                            <v-select :items="category_booking_type == '1' ? minHourly : minDaily"
                                      :placeholder="$t('Enter Minimum Time Slot')"
                                      variant="underlined"
                                      item-text="name"
                                      :disabled="disable"
                                      :readonly="category_booking_type === '0'"
                                      item-value="id"
                                      class="text-fields"
                                      v-model="minimumTime"></v-select>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12"
                           md="5"
                           sm="6">
                        <v-card-text>
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Booking Lead Time') }}
                            </p>
                            <v-select :items="cancelationTime"
                                      :placeholder="$t('Enter Booking Lead Time')"
                                      variant="underlined"
                                      item-text="name"
                                      :disabled="disable"
                                      item-value="id"
                                      class="text-fields"
                                      v-model="bookingTime"></v-select>
                        </v-card-text>
                    </v-col>

                </v-row>


                <v-row dense>
                    <v-col cols="12">
                        <v-card-text>
                            <p class="font-weight-black text-body-2 text-black">
                                {{ $t('Upload Document') }}
                            </p>
                            <div class="upload-docu mr-3"
                                 style="padding:0px !important"
                                 tile>
                                <input type="file"
                                       name="file"
                                       id="packageDocument"
                                       class="hidden-input"
                                       ref="filePackage"
                                       hidden
                                       @change="uploadImg2"
                                       accept=".pdf,.jpg,.jpeg,.png" />
                                <v-img v-if="packageImage"
                                       :src="packageImage"
                                       width="100%"
                                       style="border-radius: 10px;height: 100%;max-height: 154px;"
                                       class="me-2"></v-img>
                                <span v-if="packageImage && !disable"
                                      class="category-icon"
                                      @click="deleteBoatDocument">
                                    <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                           height="21px"
                                           width="22px"></v-img>
                                </span>
                                <label v-if="!packageImage"
                                       for="fileInput"
                                       class="file-label">
                                    <img class="imge text-green"
                                         :src="require(`@/assets/images/dummy-img.png`)"
                                         alt="">
                                </label>
                                <div v-if="!packageImage"
                                     style="cursor: pointer;"
                                     class="upload-img owner-info-btn border-card"
                                     @click="chooseFiles2()">
                                    {{ $t('Upload Image') }}
                                </div>
                            </div>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-form>

            <v-card-actions v-if="!disable">
                <v-btn class="text-capitalize px-9 text-black"
                       outlined
                       color="#00B2A9"
                       @click="back">{{ $t('Back') }}</v-btn>
                <v-btn @click="save"
                       class="text-capitalize px-11 white--text owner-info-btn border-card"
                       color="#00B2A9">{{ $t('Save') }}</v-btn>

            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
import config from '/config';
export default {
    props: {
        category: {
            default()
            {
                return {}
            },
            type: Object
        },
        disable: {
            default: false,
            type: Boolean
        },
    },
    data: () => ({

        bookingTypes: [
            {
                name: 'Daily',
                id: '0'
            },
            {
                name: 'Hourly',
                id: '1'
            },
        ],
        packageImage: null,
        imageFile: null,
        minimumTime: '',
        bookingTime: '',
        arName: '',
        description: '',
        category_booking_type: null,
        cancelationTime: [
            {
                name: '24 Hours',
                id: '00:00:00'
            },
            {
                name: '12 Hours',
                id: '12:00:00'
            },
            {
                name: '8 Hour',
                id: '08:00:00'
            },
            {
                name: '4 Hours',
                id: '04:00:00'
            }
        ],
        minHourly: [{
            name: '1 Hour',
            id: '01:00:00'
        },
        {
            name: '2 Hours',
            id: '02:00:00'
        },
        {
            name: '3 Hours',
            id: '03:00:00'
        },
        {
            name: '4 Hours',
            id: '04:00:00'
        },
        {
            name: '5 Hours',
            id: '05:00:00'
        },
        {
            name: '6 Hours',
            id: '06:00:00'
        },
        {
            name: '7 Hours',
            id: '07:00:00'
        },
        {
            name: '8 Hours',
            id: '08:00:00'
        }
        ],
        minDaily: [{
            name: '1 Day',
            id: '24:00:00'
        },
        // {
        //     name: '2 Days',
        //     id: '48:00:00'
        // },
        // {
        //     name: '3 Days',
        //     id: '72:00:00'
        // },
        // {
        //     name: '4 Days',
        //     id: '96:00:00'
        // },
        // {
        //     name: '5 Days',
        //     id: '120:00:00'
        // },
        // {
        //     name: '6 Days',
        //     id: '144:00:00'
        // },
        // {
        //     name: '7 Days',
        //     id: '168:00:00'
        // },
        // {
        //     name: '8 Days',
        //     id: '192:00:00'
        // }
        ],
    }),

    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        category: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = Object.assign({}, newVal);

                this.name = this.localObject.name;
                this.arName = this.localObject.name_arb;
                this.description = this.localObject.description;
                this.minimumTime = this.localObject.min_time_slot;
                this.bookingTime = this.localObject.booking_lead_time;
                this.category_booking_type = this.localObject.category_booking_type;

                if (this.localObject.image)
                {
                    this.packageImage = config.MYBOAT_IMG_URL + this.localObject.image
                }
                else
                {
                    this.packageImage = null;
                }
            }
        }
    },

    methods: {
        onChangeBookingType()
        {
            if(this.category_booking_type === '0')
            {
                this.minimumTime = '24:00:00'
            }
        },
        deleteBoatDocument()
        {
            this.packageImage = null;
            this.imageFile = null;

        },
        back()
        {
            this.$emit('back');
            this.$refs.form.resetValidation();
            this.localObject = {};
        },
        save()
        {

            if (this.localObject.id)
            {
                const formData = new FormData();
                formData.append('name', this.name);
                formData.append('id', this.localObject.id);
                formData.append('name_arb', this.arName);
                formData.append('description', this.description);
                formData.append('min_time_slot', this.minimumTime);
                formData.append('booking_lead_time', this.bookingTime);
                formData.append('category_image', this.imageFile);
                formData.append('category_booking_type', this.category_booking_type);
                formData.append('status', 1);
                this.$emit('updateBoat', formData);
            }
            else
            {
                const formData = new FormData();
                formData.append('name', this.name);
                formData.append('name_arb', this.arName);
                formData.append('description', this.description);
                formData.append('min_time_slot', this.minimumTime);
                formData.append('booking_lead_time', this.bookingTime);
                formData.append('category_image', this.imageFile);
                formData.append('category_booking_type', this.category_booking_type);
                formData.append('status', 1);
                this.$emit('saveBoat', formData);
            }

        },
        chooseFiles2: function ()
        {
            document.getElementById("packageDocument").click()
        },
        uploadImg2(event)
        {


            let file = event.target.files[0];
            this.imageFile = event.target.files[0];
            // console.log(file);
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.target.files[0].size / 1024 / 1024;

                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () =>
                {
                    // this.image = reader.result;
                    this.packageImage = reader.result;
                    // let base64Image = reader.result;
                    // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                    // // console.log(this.packageFile);
                    // this.uploadcardLimitImage(base64Image, file.size, file.name)

                };
            })
        },


    }
}
</script>

<style scoped>
.weight {
    font-weight: 600;
}

.color {
    color: #00B2A9;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

.category-text {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 24px;
    right: 24px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.plus-img {
    margin: auto;
    /* height: 101px; */
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: center; */
    height: 50px;
    margin-top: 46px;
}

.secure-img {
    background: #FFFFFF;
    width: 43px;
    height: 41px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
    position: absolute;
    right: 9px;
    top: 5px;
}

.attach-file {
    height: 25px;
    margin: 4px;
}

.document {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.owner-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.packages {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.boat-condition {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.cross {
    width: 24px;
    height: 22px;
    position: absolute;
    right: -12px;
}

.upload-docu {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 38px 0px;
}
</style>



